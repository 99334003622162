import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PageHeaderComponent} from '../components/page-header/page-header.component';
import {RouterModule} from '@angular/router';
import {MatModule} from './mat.module';
import {LazyLoadImageModule, LAZYLOAD_IMAGE_HOOKS, ScrollHooks} from 'ng-lazyload-image';
import {AutoCompleteModule} from 'ionic4-auto-complete';
import {CalltoactionComponent} from '../components/calltoaction/calltoaction.component';
import {SemainierComponent} from '../components/semainier/semainier.component';
import {IonicModule} from '@ionic/angular';
import {FriseComponent} from '../components/frise/frise.component';
import {BlurhashComponent} from '../blurhash/blurhash.component';
import {DeviceComponent} from '../components/device/device.component';
import {GrapheComponent} from '../components/graphe/graphe.component';
import {TranslateModule} from '@ngx-translate/core';
import {OuvertureevComponent} from '../components/ouvertureev/ouvertureev.component';
import {ElectrovanneComponent} from '../electrovanne/electrovanne.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FiltreinterventionPipe} from './../filtreintervention.pipe';
import {FiltreinterventionTypePipe} from './../filtreintervention-type.pipe';
import {FiltreinterventionUrgentPipe} from './../filtreintervention-urgent.pipe';
import {PhoneNumberFilterPipe} from '../phone-number-filter.pipe';
import {UserRoleKeyPipe} from '../user-role-key.pipe';
import {CreermissionPage} from '../interventions/interventionlist/creermission/creermission.page';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {ChoixinstallateurPage} from '../interventions/choixinstallateur/choixinstallateur.page';
import {StatusBadgeComponent} from '../components/atoms/status-badge/status-badge.component';
import { DetailinterventionPage } from '../interventions/detailintervention/detailintervention.page';
import {InterventionTypeBadgeComponent} from '../components/atoms/intervention-type-badge/intervention-type-badge.component';
import {InterventionRowComponent} from '../components/molecules/intervention-row/intervention-row.component';
import {MissionRowComponent} from '../components/molecules/mission-row/mission-row.component';
import {InterventionNavComponent} from '../components/organisms/intervention-nav/intervention-nav.component';
import { ConfirmationdialogComponent } from '../interventions/confirmationdialog/confirmationdialog.component';
import { SondesComponent } from '../components/sondes/sondes.component';
import {ResponsiveTabBarComponent} from '../components/molecules/responsive-tab-bar/responsive-tab-bar.component';
import {ProjectChronologyComponent} from '../components/molecules/project-chronology/project-chronology.component';
import { FilterPipe } from '../filter.pipe';
import {
    ProjectSubscriptionComponent
} from '../components/molecules/project-subscription/project-subscription.component';
import {
    InterventionRawListComponent
} from '../components/organisms/intervention-raw-list/intervention-raw-list.component';
import {FileListComponent} from '../components/molecules/file-list/file-list.component';
import {GalleryGroupComponent} from '../components/organisms/gallery-group/gallery-group.component';
import {NgImageFullscreenViewModule} from 'ng-image-fullscreen-view';
import {PhotosListComponent} from '../components/organisms/photos-list/photos-list.component';
import {PhotoCardComponent} from '../components/molecules/photo-card/photo-card.component';
import {RiskBadgeComponent} from '../components/atoms/risk-badge/risk-badge.component';
import {OffreGuardComponent} from '../components/atoms/offre-guard/offre-guard.component';
import {ModalHeaderComponent} from '../components/organisms/modal-header/modal-header.component';
import {LoadBtnComponent} from '../components/atoms/load-btn/load-btn.component';
import {MaintenanceConfirmDialogComponent} from '../components/dialogs/maintenance-confirm-dialog/maintenance-confirm-dialog.component';
import {LoaderComponent} from '../components/atoms/loader/loader.component';
import {InstallateurCardComponent} from '../components/molecules/installateur-card/installateur-card.component';
import { AffichagedendroComponent } from '../components/molecules/affichagedendro/affichagedendro.component';
import { AffichageSport6sComponent } from '../components/molecules/affichage-sport6s/affichage-sport6s.component';
import {CounterComponent} from '../components/atoms/counter/counter.component';
import {CounterListComponent} from '../components/molecules/counter-list/counter-list.component';
import {InterventionRemarqueComponent} from '../components/atoms/intervention-remarque/intervention-remarque.component';
import {
    InterventionCountersComponent
} from '../components/molecules/intervention-counters/intervention-counters.component';
import {CounterSimpleComponent} from '../components/atoms/counter-simple/counter-simple.component';
import {TimeDetailComponent} from '../components/atoms/time-detail/time-detail.component';
import {MissionEditorComponent} from '../components/organisms/mission-editor/mission-editor.component';
import {CollapsableComponent} from '../components/atoms/collapsable/collapsable.component';
import {DataRowComponent} from '../components/atoms/data-row/data-row.component';
import { SelectwithfilterComponent } from '../components/selectwithfilter/selectwithfilter.component';

@NgModule({
    declarations: [
        AffichagedendroComponent,
        SelectwithfilterComponent,
        BlurhashComponent,
        CalltoactionComponent,
        ChoixinstallateurPage,
        ConfirmationdialogComponent,
        CounterComponent,
        CounterListComponent,
        CreermissionPage,
        DetailinterventionPage,
        DeviceComponent,
        ElectrovanneComponent,
        FileListComponent,
        FiltreinterventionPipe,
        FiltreinterventionTypePipe,
        FiltreinterventionUrgentPipe,
        PhoneNumberFilterPipe,
        CalltoactionComponent,
        ElectrovanneComponent,
        SemainierComponent,
        PageHeaderComponent,
        FriseComponent,
        GalleryGroupComponent,
        GrapheComponent,
        InstallateurCardComponent,
        InterventionNavComponent,
        InterventionRawListComponent,
        InterventionRowComponent,
        InterventionTypeBadgeComponent,
        LoadBtnComponent,
        LoaderComponent,
        MaintenanceConfirmDialogComponent,
        MissionRowComponent,
        ModalHeaderComponent,
        OffreGuardComponent,
        OuvertureevComponent,
        PageHeaderComponent,
        PhotoCardComponent,
        PhotosListComponent,
        ProjectChronologyComponent,
        ProjectSubscriptionComponent,
        ResponsiveTabBarComponent,
        RiskBadgeComponent,
        SemainierComponent,
        SondesComponent,
        StatusBadgeComponent,
        UserRoleKeyPipe,
        FilterPipe,
        OffreGuardComponent,
        ModalHeaderComponent,
        AffichagedendroComponent,
        AffichageSport6sComponent,
        LoadBtnComponent,
        MaintenanceConfirmDialogComponent,
        LoaderComponent,
        InstallateurCardComponent,
        InterventionRemarqueComponent,
        SemainierComponent,
        SondesComponent,
        StatusBadgeComponent,
        UserRoleKeyPipe,
        InterventionCountersComponent,
        CounterSimpleComponent,
        TimeDetailComponent,
        MissionEditorComponent,
        CollapsableComponent,
        DataRowComponent,
    ],
    imports: [
        AutoCompleteModule,
        CommonModule,
        FormsModule,
        InfiniteScrollModule,
        IonicModule,
        LazyLoadImageModule,
        LeafletModule,
        MatModule,
        NgImageFullscreenViewModule,
        ReactiveFormsModule,
        RouterModule,
        TranslateModule,
    ],
    providers: [{provide: LAZYLOAD_IMAGE_HOOKS, useClass: ScrollHooks}],
    exports: [
        AffichagedendroComponent,
        SelectwithfilterComponent,
        AffichageSport6sComponent,
        AutoCompleteModule,
        BlurhashComponent,
        CalltoactionComponent,
        ChoixinstallateurPage,
        ConfirmationdialogComponent,
        CounterComponent,
        CounterListComponent,
        CreermissionPage,
        DetailinterventionPage,
        DeviceComponent,
        ElectrovanneComponent,
        FiltreinterventionPipe,
        FiltreinterventionTypePipe,
        FiltreinterventionUrgentPipe,
        FilterPipe,
        PhoneNumberFilterPipe,
        FormsModule,
        FriseComponent,
        GalleryGroupComponent,
        GrapheComponent,
        InfiniteScrollModule,
        InterventionNavComponent,
        InterventionRawListComponent,
        InterventionRowComponent,
        InterventionTypeBadgeComponent,
        LazyLoadImageModule,
        MatModule,
        MissionRowComponent,
        ModalHeaderComponent,
        OffreGuardComponent,
        OuvertureevComponent,
        PageHeaderComponent,
        PhotoCardComponent,
        PhotosListComponent,
        ProjectChronologyComponent,
        ProjectSubscriptionComponent,
        ReactiveFormsModule,
        ResponsiveTabBarComponent,
        RiskBadgeComponent,
        SemainierComponent,
        SondesComponent,
        StatusBadgeComponent,
        TranslateModule,
        UserRoleKeyPipe,
        InterventionCountersComponent,
        CounterSimpleComponent,
        TimeDetailComponent,
        InterventionRemarqueComponent,
        InstallateurCardComponent,
        MissionEditorComponent,
        CollapsableComponent,
        DataRowComponent,
        LoaderComponent,
    ]
})
export class SharedModule {
}
