import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-detectnavigator',
  templateUrl: './detectnavigator.component.html',
  styleUrls: ['./detectnavigator.component.scss'],
})
export class DetectnavigatorComponent implements OnInit {
  isChrome = navigator.userAgent.indexOf('Chrome') > -1;
  isClosed = false;
  constructor() {

    console.log('navigator.userAgent', navigator.userAgent);
   }

  ngOnInit() {}


  closeWindows(){
    this.isClosed = true;
  }

}
