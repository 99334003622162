<div  class="detectnavigator" *ngIf="!isChrome && !isClosed" >
  <div style="   display: flex;
  align-items:center;height:80px;">
  <img src="../../../assets/icons/googlechromeicon.png" style="height:80%;vertical-align: middle;" alt="Urbasense" class="logo" />
  <span style="line-height:15px">Urbasense vous conseille l'utilisation de Google Chrome pour une meilleure expérience sur notre application.</span>
  </div>

<span><ion-button size="small" (click)="closeWindows()">
  Ok j'ai compris
</ion-button></span>
</div>